:root {
  /* violet */
  --primary-color: 111, 76, 255;

  /* white */
  --text-color: 256, 256, 256;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Mont-Bold";
  src: local("Mont-Bold"), url("./fonts/Mont-Black.otf") format("truetype");
}

@font-face {
  font-family: "Mont-Regular";
  src: local("Mont-Regular"), url("./fonts/Mont-Regular.otf") format("truetype");
}

.textGrad {
  background: linear-gradient(
    91.73deg,
    #c72891 -1.18%,
    #012ece -1.17%,
    #89f8ff 49.77%,
    #c72891 102.89%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.textGradSameLine {
  background: linear-gradient(91.73deg, #89f8ff 49.77%, #c72891 102.89%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.linkText {
  color: #00aff0;
  font-weight: 800;
}

.btn {
  font-family: "Poppins", sans-serif;
  position: relative;

  padding: 12px 32px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  border-radius: 10px;
}

.rgb2::before {
  content: " ";
  background: linear-gradient(
      45deg,
      #ff0000 0%,
      #ff9a00 10%,
      #d0de21 20%,
      #4fdc4a 30%,
      #3fdad8 40%,
      #2fc9e2 50%,
      #1c7fee 60%,
      #5f15f2 70%,
      #ba0cf8 80%,
      #fb07d9 90%,
      #ff0000 100%
    )
    repeat 0% 0% / 300% 100%;
  position: absolute;

  padding: 30px 140px;
  top: 0%;
  right: 0%;
  border-radius: 10px;
  filter: blur(8px);
  z-index: -1; /*or z-index */
  animation: rgb 6s linear infinite;
}

.rgb3::before {
  content: " ";
  background: linear-gradient(
      90deg,
      #ff0000 0%,
      #ff9a00 10%,
      #d0de21 20%,
      #4fdc4a 30%,
      #3fdad8 40%,
      #2fc9e2 50%,
      #1c7fee 60%,
      #5f15f2 70%,
      #ba0cf8 80%,
      #fb07d9 90%,
      #ff0000 100%
    )
    repeat 0% 0% / 300% 100%;
  position: absolute;

  padding: 660px 2px;
  top: 5%;
  right: 50%;
  border-radius: 10px;
  filter: blur(8px);
  z-index: -1; /*or z-index */
  animation: rgb 2s linear infinite;
}

.grad {
  animation: rgb 2s linear infinite;
}
@keyframes rgb {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.btn-sml-txt {
  font-family: "Poppins", sans-serif;
  padding: 12px 32px;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  border-radius: 10px;
}

.btn:hover {
  transition: all 0.3s ease;
}

.btn-gradient-border {
  color: white;
  border: 2px double transparent;
  background-image: linear-gradient(rgb(13, 14, 33), rgb(13, 14, 33)),
    radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.btn-glow:hover {
  box-shadow: rgba(var(--primary-color), 0.5) 0px 0px 20px 0px;
}

#progressbar {
  transition: all 1.2s linear;
}

#c0,
#c1,
#c2 {
  opacity: 0;
}

.active-roadmap {
  animation: hello 2.5s linear;
}

@keyframes hello {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  40% {
    transform: scale(0.5);
    opacity: 0;
  }
  60% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
